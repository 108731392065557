:root {
  ---primary: #61dafb;
  ---offwhite: #f5f5f5;
  ---success: #155724;
  ---successSecondary: #d4edda;
  ---warning: #856404;
  ---warningSecondary: #fff3cd;
  ---danger: #721c24;
  ---dangerSecondary: #f8d7da;
  ---attention: #1a0907;
  ---attentionSecondary: #f29b38;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;

  min-height: 100vh;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;

  width: 100%;
  /* Set the fixed height of the footer here */
  height: 80px;

  line-height: 2;

  background-color: var(---offwhite);
}

.nav-link {
  cursor: pointer;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container {
  padding: 60px 15px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}

.thumbnail {
  width: 100px;
}

.thumbnail-container {
  height: 120px;
}

.success,
tr.success > td {
  color: var(---success);
  background-color: var(---successSecondary);
}

.warning,
tr.warning > td {
  color: var(---warning);
  background-color: var(---warningSecondary);
}

.danger,
tr.danger > td {
  color: var(---danger);
  background-color: var(---dangerSecondary);
}

.attention,
tr.attention > td {
  color: var(---attention);
  background-color: var(---attentionSecondary);
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;

  height: 40vmin;

  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  font-size: calc(10px + 2vmin);

  color: white;
  background-color: #282c34;
}

.App-link {
  color: var(---primary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.webcontent__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webcontent__textbox {
  width: 970px;
  height: 1000px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-style: italic;
}

.webcontent__update_msg {
  margin-left: 190px;
}

.webcontent__update_btn {
  margin-left: 696px;
}

.caseId_selector {
  width: 25% !important;
}

.in_office_print_upload_button {
  padding-left: 10px;
}

.casId_text {
  padding-right: 11px;
}

.in_office_print_note_text {
  margin-top: 4px;
}

.photos_spinner {
  width: 100%;
  height: 680px;

  text-align: center;
  vertical-align: middle !important;
}

.toast-alert {
  left: 50%;

  max-width: 300px;

  transform: translateX(-50%);
}

.layout__main {
  padding: 20px 0;
}
